import React, { useState } from "react"
import TwentyTwenty from 'react-twentytwenty';
import styled from 'styled-components';
import sliderCompareControl from "../../images/icon/slider-compare-control.svg"

const SliderIconContainer = styled.div`
  height: 100%;
  width: 2px;
  background-color: #fff;
  cursor: col-resize;
`;

const Slider = styled.img`
  top: 50%;
  height: 65px;
  width: 65px;
  position: absolute;
  transform: translate(-50%, -50%);
`;

const ImageComparator = ({ before, after }) => {
  const [position, setPosition] = useState(0.4)

  return (
    <TwentyTwenty
      verticalAlign="bottom"
      minDistanceToBeginInteraction={0}
      maxAngleToBeginInteraction={Infinity}
      position={position}
      onChange={setPosition}
      left={<img alt="cat-1" src={ before } />}
      right={<img alt="cat-2" src={ after } />}
      slider={
        <SliderIconContainer>
          <Slider src={sliderCompareControl} alt="slider handle" />
        </SliderIconContainer>
      }
    />
  )
}

export default ImageComparator
