import React from "react"
import corporateImage from "../images/service.jpg"

import HeaderStyle1 from "../components/Header/HeaderStyle1"
import SectionTitle from "../components/SectionTitle/SectionTitle"
import ImageComparator from "../components/Features/ImageComparator"
import ReactCompareImage from 'react-compare-image';
import TransactionGridCol4 from "../components/Portfolio/TransactionGridCol4"
import FooterStyle1 from "../components/Footer/FooterStyle1"
import FeatureStyle7 from '../components/Features/FeatureStyle7'
import TeamStyle5 from "../components/Team/TeamStyle5"
import {
  FaChartLine,
  FaRegThumbsUp,
  FaThumbsUp,
  FaMoneyCheck,
  FaCamera,
  FaNewspaper,
  FaUserFriends,
  FaChessKing
} from "react-icons/fa"
import transaction_list from "../components/transaction_list"
import lang from "../components/lang"
import Video from "../components/video"
import { FaCheckCircle } from "react-icons/fa"
import Before1 from "../images/before1.jpg"
import Before2 from "../images/before2.jpg"
import Before3 from "../images/before3.jpg"
import Before4 from "../images/before4.jpg"
import After1 from "../images/after1.jpg"
import After2 from "../images/after2.jpg"
import After3 from "../images/after3.jpg"
import {Helmet} from "react-helmet"
import Zillow from "../images/zillow2.jpg"
import Sell1 from "../images/sell1.jpg"
import CallToAction2 from "../components/CallToAction/CallToAction2"

// https://www.vickyhousing.com/sellers/
// https://www.npmjs.com/package/react-twentytwenty
// https://www.safewise.com/blog/safest-cities-california/

const Homepage6 = () => {
  return (
    <>
      <HeaderStyle1 />
      <div className="application">
          <Helmet>
              <meta charSet="utf-8" />
              <title>湾区最会卖房的经纪人 | 湾区卖房 湾区Top华人房产经纪 | 熟悉南湾、东湾、三谷市场 | Remax Accord 排名第一 经纪人 | 近12个月成交量超1亿美金</title>
              <meta name="description" content="一支秉持诚信、负责、专业的湾区TOP 1% 房产经纪人团队，团队提供房屋上市、房屋贷款、房屋装修、staging、清洁等服务，熟悉南湾、东湾、三谷市场，全面考虑客户利益！" />
              <meta name="Keywords" content="湾区,三谷,华人房产经纪,南湾,东湾,湾区卖房,湾区卖房流程,listing,地产经纪,pleasanton,fremont,cupertino,san jose,mountain view,dublin,palo alto,melo park,livermore,dublin,danville"></meta>
              <meta name="author" content="Jing Xue" />
              <link rel="canonical" href="https://www.jingxuehomes.com" />

              <script type="application/ld+json">{`
              {
                "@context": "https://schema.org",
                "@type": "FAQPage",
                "mainEntity": [{
                  "@type": "Question",
                  "name": "出售的房产如何定价?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "<p>在最短的时间内帮您把房子卖到<strong>最高的价格</strong>是我们的目标。Jing 出售的房产持续创造全小区最高价。</p>"
                  }
                },{
                  "@type": "Question",
                  "name": "房产上市前需要做哪些准备?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "<p>Jing Xue地产经纪团队拥有独立的staging团队，对于卖家，我们会根据房子的情况提供一条龙上市前的包装，最优秀最具性价比的服务团队共您选择，最大化房子的出售价格。</p>"
                  }
                }]
              }
              `}</script>
          </Helmet>
      </div>
      <section className="pad30" style={{ backgroundColor: "#f3f3f3" }}>
          <div className="container">
          <div className="row">
              <div className="col-md-7">
              <SectionTitle
                  titleStyle="left"
                  title={lang === "EN" ? "Why you will Choose Jing": "湾区卖房"}
              />
              <div className="row mbl-margin-bottom">
                  <div className="col-md-12">
                  <FeatureStyle7
                      icon={<FaChartLine />}
                      title={lang === "EN" ? "Free Comparable marketing analysis": "专业的数据驱动的市场分析,帮你的House设定合理的价格"}
                  />
                  </div>
                  <div className="col-md-12">
                  <FeatureStyle7
                      icon={<FaRegThumbsUp />}
                      title={lang === "EN" ? "Free Professional full staging": "专业公司装修，免费Staging和清洁,轻松增值你的House"}
                  />
                  </div>
                  <div className="col-md-12">
                  <FeatureStyle7
                      icon={<FaUserFriends />}
                      title="强大的湾区房产经纪人网络，帮你的房产卖到最好的价格"
                  />
                  </div>
                  <div className="col-md-12">
                  <FeatureStyle7
                      icon={<FaMoneyCheck />}
                      title={lang === "EN" ? "Free Professional photography": "专属税务咨询，最大化卖家利益"}
                      subtitle="适用于持有几套房屋的卖家"
                  />
                  </div>
                  <div className="col-md-12">
                  <FeatureStyle7
                      icon={<FaCamera />}
                      title="印刷高质量卖房flyer、拍照以及3D tour服务"
                  />
                  </div>
                  <div className="col-md-12">
                  <FeatureStyle7
                      icon={<FaNewspaper />}
                      title={lang === "EN" ? "Free Online marketing including redfin.com": "免费多渠道的推广,包括redfin,微信公众号"}
                      subtitle="包括redfin,微信公众号,小红书,网站"
                  />
                  </div>
                  <div className="col-md-12">
                  <FeatureStyle7
                      icon={<FaChessKing />}
                      title={"持续创造全小区最高价"}
                  />
                  </div>
              </div>
              </div>
              <div className="col-md-5">
              <img src={corporateImage} alt="" className="w-100" />
              <h4
                  style={{
                  marginTop: "30px",
                  marginBottom: "15px",
                  fontSize: "18px",
                  }}
              >
                  About Jing
              </h4>
              <p>
              {
              lang === "EN" ? 
              "Always committed to providing a high level of service, Jing is known for timely and concise communication, always giving her best to exceed her clients' expectations. As a good listener, Jing will be responsive to all your needs, and guide new and experienced home buyers and sellers through every step of the process, and navigates complex transactions with ease and expertise to deliver results.": 
              "在最短的时间内帮您把房子卖到最高的价格是我们的目标。对于卖家，我们会根据房子的情况提供一条龙上市前的包装，最优秀最具性价比的服务团队共您选择，核查全部信息并提前调整以规避风险，多元化全方位宣传，核查买家信息规避风险，业内口碑和人脉带来同行看房流量，保证交易按时顺利完成。 "}
              </p>
              </div>
          </div>
          </div>
      </section>

      {/* Start Team Section*/}
      <section className="pad30">
          <div className="container">
              <div className="row">
                  <div className="col-md-12">
                  <SectionTitle title="团队成员" />
                  </div>
              </div>
              <TeamStyle5 />
          </div>
      </section>
      {/* End Team Section*/}
      <section>
          <div className="row">
              <div className="col-md-12">
                  <div className="mb-5">
                      <CallToAction2 bgColor="#222" paddingClass="pad80" text={"我要卖房，请联系Jing Xue咨询"}/>
                  </div>
              </div>
          </div>
      </section>
      {/* Start Features Section */}
      <section className="pad-b30">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <SectionTitle title="成功装修案例" />
            </div>
          </div>
          <div className="post-content">
              <div className="post-title">
                <h3>高效谈判，认真负责</h3>
              </div>
              <br/>
              <div className="post-text">
                  <p>这是一栋位于Pleasanton半山腰的独立屋，整个社区只有15栋房子，每栋都有独特的外观设计，享有无敌的景观。Jing为客户以比listing价格低38万的价格拿下。</p>
                  <p>客户在看完这套房子的第二天就要上飞机回国度假，当晚我们我们分析了卖家的心理之后，递上了一个比开价低38万的offer。在第二天与对方经纪的来回电话后成功进入合同。</p>
                  <p>有人问为什么敢去出一个低于开价38万的offer？不是所有的房子我们都可以这么做，买卖房产中，不仅要分析市场数字，了解市场动态，更主要的是掌控对方心理，打好心理战尤其重要，这样才能让我们的谈判更自信更高效！</p>
                  <p>由于前一个买家贷款出现问题退出，了解到买家这时所有搬家的项目都已经进行到了一半，当时已经是进退两难，需要的是一个急需接手的买家。价格方面，现在的售价其实已经是购买价的4倍。这种情况下，再加上对方经纪人的配合，自然是成功低价拿下！</p>
                  <p>由于客户在国内，再加上是不查收入的贷款，整个交易自然是阻碍重重，我们克服了各种时差、银行与交易公司的各种手续操作问题，顺利按时完成交易！在我们这里，太多的不可能都可以成为可能！</p>
              </div>
          </div>
          <div className="row">
            <img src={Sell1} alt=""/>
          </div>
          <div className="post-content">
              <div className="post-title">
                <h3>独到的眼光，有时候我们比客户更上心！</h3>
              </div>
              <br/>
              <div className="post-text">
                  <p>2020年年初，一位客户从zillow上看到客户对我的评价后电话找到我，让我接手他们在市场上卖了8个月还没出售的一栋San Ramon Gale Ranch独立屋，客户已经搬到德州，每一天在市场上对他们来说都是成本。</p>
                  <p>经过分析后，我们马上找到潜在的问题的所在，房屋略暗，装饰风格略显陈旧。签了Listing agreement后，我们前后花了七天的时间，重新改了墙面漆、室内设计风格、深度清洁、专业摄影后完美上市，上市两天就接到三个offer，最后高于listing价格出售。</p>
                  <p>open house的时候很多人夸赞了客厅的吊灯，赞其有画龙点睛的效果，其实里面还有一个小插曲，当时提出换灯的时候客户不愿意再加上这个成本，但是我们坚信这个等完全是可以带来不一样的效果，专业摄影的前一晚，JING自掏腰包花了二百多美金买来吊灯让装修师傅连夜装上。买卖过程中，我们比客户更上心！</p>
                  <p>Family Room 前后对比</p>
              </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <ReactCompareImage leftImage="https://imgcache.dealmoon.com/thumbimg.dealmoon.com/dealmoon/746/9d9/8ca/aabf16f9e48932fe90f5de7.png_800_0_40_0247.png" rightImage="https://imgcache.dealmoon.com/thumbimg.dealmoon.com/dealmoon/366/a57/5bd/525503ca2684d822003a045.jpg_800_0_40_073b.jpg" />
            </div>
            <div className="col-md-6">
              <ReactCompareImage 
                leftImage={Before2}
                rightImage={After2}
              />
            </div>
          </div>
          <br/>
          <div className="post-content">
              <div className="post-title">
                <h3>钥匙交给我们，请你们只等收钱！</h3>
              </div>
              <br/>
              <div className="post-text">
                  <p>我们有很多年轻的工程师小伙伴，由于平时工作忙，周末又要带娃，在卖方过程中，你们需要做的就是给我们一个钥匙，我们团队会在最短的时间内给你的房子来一个大变身！</p>
                  <p>下面这个是San Jose 95123的一套独立屋，小伙伴搬家到圣地亚哥，匆忙地把钥匙交给我们后，两周后，我们华丽丽地在疫情期间上市并以比listing价格高7万售出。在Jing经纪人团队专业的一条龙服务下，卖房不再是一道难题。</p>
                  <p>Family Room ,Dining Room, Sun Room 前后对比</p>
              </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <ReactCompareImage 
                leftImage={Before4}
                rightImage="https://imgcache.dealmoon.com/thumbimg.dealmoon.com/dealmoon/73b/090/40d/0a1b8a6a796f52025b8ef4b.jpg_800_0_40_a1ff.jpg"
              />
            </div>
            <div className="col-md-6">
              <ReactCompareImage 
                leftImage="https://imgcache.dealmoon.com/thumbimg.dealmoon.com/dealmoon/8b7/81e/1ea/98a13b6d4636c3e8d69af05.png_800_0_40_bc9a.png"
                rightImage="https://imgcache.dealmoon.com/thumbimg.dealmoon.com/dealmoon/686/374/db2/099974f1389f43861b2ed74.jpeg_800_0_40_1eb4.jpeg"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <ReactCompareImage 
                leftImage={Before1}
                rightImage={After1}
              />
            </div>
            <div className="col-md-6">
              <ReactCompareImage 
                leftImage={Before3}
                rightImage={After3}
              />
            </div>
          </div>
        </div>
      </section>
      {/* End Features Section */}

      <section className="pad30" style={{ backgroundColor: "#f3f3f3" }}>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <SectionTitle title="成功大幅加价出售案例 屡创小区最高价" />
            </div>
          </div>
        <div className="row">
          <div className="col-md-6">
                <img src="https://res.cloudinary.com/realtoragents-us/image/upload/v1619938837/edf68138f2b35a3d1b1c1f197a33bce6-uncropped_scaled_within_1344_1008_qspeed.webp" alt="" className="w-100" />
                <h4
                    style={{
                    marginTop: "30px",
                    marginBottom: "15px",
                    fontSize: "18px",
                    }}
                >
                    4376 Dali St
                </h4>
                <ul className="fa-ul">
                    <li>
                        <FaCheckCircle /> 上市价格: $1,198,000
                    </li>
                    <li>
                        <FaCheckCircle /> Offer数量: 12
                    </li>
                    <li>
                        <FaCheckCircle /> 加价20万，最终成交价: $1,380,000 全小区最高价  <FaThumbsUp />
                    </li>
                </ul>
            </div>
            <div className="col-md-6">
                <img src="https://res.cloudinary.com/realtoragents-us/image/upload/v1619938968/2f75fcf26368bb8abe178ef51c1692b6-uncropped_scaled_within_1344_1008_z6ofzf.webp" alt="" className="w-100" />
                <h4
                    style={{
                    marginTop: "30px",
                    marginBottom: "15px",
                    fontSize: "18px",
                    }}
                >
                    37499 Bay Crest Rd
                </h4>
                <ul className="fa-ul">
                    <li>
                        <FaCheckCircle /> 上市价格: $1,198,000
                    </li>
                    <li>
                        <FaCheckCircle /> Offer数量: 10+
                    </li>
                    <li>
                        <FaCheckCircle /> 加价6万，最终成交价: $1,261,888 全小区最高价 <FaThumbsUp />
                    </li>
                </ul>
            </div>
        </div>
        </div>
      </section>

      {/* Start Portfolio Section*/}
      <section className="pad-t30 pad-b30">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <SectionTitle title="Jing's Staging" />
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <Video
                videoSrcURL="https://www.youtube.com/embed/_QfDx6os-ZQ"
                videoTitle="5538 Carew Way, San Jose, 95123 HD 1080p"
              />
            </div>
            <div className="col-md-4">
              <Video
                videoSrcURL="https://www.youtube.com/embed/HCFDoCDYg-8"
                videoTitle="Coming Soon Listing by Jing Xue"
              />
            </div>
            <div className="col-md-4">
              <Video
                videoSrcURL="https://www.youtube.com/embed/CRx9xNUDZ-Y"
                videoTitle="Jing Xue Tamworth Ln, San Ramon, CA 94582 UNBRANDED"
              />
            </div>
            <div className="col-md-4">
              <Video
                videoSrcURL="https://www.youtube.com/embed/xz7nXMkb6kE"
                videoTitle="New listing at 621 Kirkstone Ct San Ramon"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <Video
                videoSrcURL="https://www.youtube.com/embed/MGJndEFnzm4"
                videoTitle="Jing Xue New Listing 2530 Tamworth Ln, San Ramon, CA 94582"
              />
            </div>
            <div className="col-md-4">
              <Video
                videoSrcURL="https://www.youtube.com/embed/CSD6PDcFgMU"
                videoTitle="Jing Xue Proudly Presents 319 Langton Ct, San Ramon, CA 94582"
              />
            </div>
            <div className="col-md-4">
              <Video
                videoSrcURL="https://www.youtube.com/embed/CNmy8efRZRY"
                videoTitle="Jing (Simone ) Xue New Listing at 3416 Ironwood Dr, San Ramon, CA 94582, USA"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <Video
                videoSrcURL="https://www.youtube.com/embed/xsKPFDTBfXk"
                videoTitle="Jing Xue Real Estate Team_3015 Montbretia Way, San Ramon CA 94582"
              />
            </div>
          </div>
        </div>
      </section>
      {/* End Portfolio Section*/}
      <section className="pad-t30 pad-b50">
        <div className="container">
          <div className="row">
              <div className="template-image text-center">
                  <img src={Zillow} alt="" />
              </div>
          </div>
        </div>
      </section>
      {/* Start Portfolio Section*/}
      <section className="pad-t30 pad-b50">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <SectionTitle title="近期交易" />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <TransactionGridCol4 transactions={transaction_list}/>
            </div>
          </div>
        </div>
      </section>
      {/* End Portfolio Section*/}

      {/* Start Footer Section*/}
      <FooterStyle1 />
      {/* End Footer Section*/}
    </>
  )
}

export default Homepage6
